// libs
import 'react-alice-carousel/lib/alice-carousel.css';
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect
} from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material';
import AliceCarousel from 'react-alice-carousel';
import ArrowExpand from '../../icons/ArrowExpand';
import ArrowCollapse from '../../icons/ArrowCollapse';

// styles
import useScreenElements from '../../utils/customHooks/useScreenElements';
import { dynamicImport } from '../../utils/reactUtils';
import styles, {
  propertyDetailCarouselDots,
  propertyDetailCarouselDotsItem,
  propertyDetailCarouselDotsItemActive
} from './styles';
import { propertyFallbackImage } from '../../utils/Constants/fallbackImageUrl';
import useClientOnly from '../../utils/customHooks/useClientOnly';

const Dialog = dynamicImport(() => import('@mui/material/Dialog'));

export interface LibResponsiveCarouselProps {
  dataImg: Array<{ id: string; title: string; srcSet: string }>;
  imgWidth?: string;
  imgHeight?: string;
  isDisableFullscreen?: boolean;
  isDisableSlideCount?: boolean;
  isTransparentNavigation?: boolean;
  isEagerImg?: boolean;
  isNewHomesDetails?: boolean;
  isPropertyDetail?: boolean;
  isPropertyListCards?: boolean;
  isAlreadyFullScreen?: boolean;
  disableDotsControls?: boolean;
  keepTrackOfActiveIndex?: boolean;
  keyboardNavigation?: boolean;
  handleFullScreen?: (v: boolean) => void;
  photoAspectRatio?: string;
  // used as root margin for IntersectionObserver
  intersectionRootMargin?: string;
}

const getInnerWidth = () => {
  try {
    // if client
    return window.innerWidth;
  } catch (e) {
    // if server, set any desired value
    return 1024;
  }
};

const transparentOverlayStyles = {
  height: 'auto',
  padding: 0,
  paddingTop: {
    xs: 0,
    md: 0
  },
  top: 'calc(50%)',
  background: 'transparent',
  opacity: 1,
  margin: 0
};

const navigationOverlay = {
  '& .alice-carousel__next-btn': transparentOverlayStyles,
  '& .alice-carousel__prev-btn': transparentOverlayStyles
};

const aliceDotsStyles = {
  '& .alice-carousel__dots': propertyDetailCarouselDots,
  '& .alice-carousel__dots-item': propertyDetailCarouselDotsItem,
  '& .alice-carousel__dots-item.__active': propertyDetailCarouselDotsItemActive
};

const imageLoader = ({ src, containerSize = undefined }) => {
  if (typeof window === 'undefined') {
    return src.replace('w/1600', `w/480`);
  }

  let width = 1600;
  const windowsWidth = containerSize || window.innerWidth;

  if (windowsWidth <= 480) {
    width = 480;
  } else if (windowsWidth <= 720) {
    width = 720;
  } else if (windowsWidth <= 1280) {
    width = 1280;
  } else if (windowsWidth <= 1600) {
    width = 1600;
  } else {
    width = 2200;
  }

  return src.replace('w/1600', `w/${width}`);
};

const imageLoaderWithRetina = ({ src, containerSize = undefined }) => ({
  src: imageLoader({ src, containerSize }),
  retinaSrc: imageLoader({ src, containerSize: containerSize * 2 })
});

function Carousel({
  dataImg = [],
  isDisableFullscreen,
  isDisableSlideCount,
  isTransparentNavigation,
  isNewHomesDetails = false,
  isPropertyDetail = false,
  isPropertyListCards = false,
  disableDotsControls = true,
  keepTrackOfActiveIndex = false,
  fullscreen,
  toggleFullScreen,
  bgRef,
  activeIndex,
  sliderRef,
  onSlideChanged,
  handleOnDragStart,
  getImageStyles,
  handlePrevSlide,
  handleNextSlide,
  parentRef,
  keyboardNavigation = false,
  photoAspectRatio = 'auto',
  intersectionRootMargin = '50%'
}) {
  const { hasMounted } = useClientOnly();
  const [isLoaded, setIsLoaded] = useState(false);
  const [containerRef, isVisible] = useScreenElements({
    reappear: false,
    rootMargin: intersectionRootMargin
  });

  const propertyDetailCarouselWrapperStyles = {
    '.alice-carousel__wrapper': {
      aspectRatio: photoAspectRatio
    }
  };

  const fixWidth = useCallback(() => {
    const carouselWrapperRef = parentRef?.current;

    if (carouselWrapperRef) {
      const wrapperWidth = carouselWrapperRef.offsetWidth;
      const allSlides = carouselWrapperRef.querySelectorAll(
        '.alice-carousel__stage-item'
      );
      const activeElement = carouselWrapperRef.querySelector('.__active');

      if (activeElement && activeElement.style.width !== `${wrapperWidth}px`) {
        activeElement.style.width = `${wrapperWidth}px`;
      }

      allSlides.forEach((val) => {
        if (val.style.width !== `${wrapperWidth}px`) {
          // eslint-disable-next-line no-param-reassign
          val.style.width = `${wrapperWidth}px`;
        }
      });
    }
  }, [parentRef]);

  useEffect(() => {
    const images = document.querySelectorAll('.alice-carousel img');
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages += loadedImages;

      if (loadedImages === images.length) {
        setIsLoaded(true);
      }
    };

    images.forEach((image) => {
      if (image?.complete) {
        handleImageLoad();
      } else {
        image.addEventListener('load', handleImageLoad);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', handleImageLoad);
      });
    };
  }, [dataImg]);

  useLayoutEffect(() => {
    if (isLoaded) {
      window.addEventListener('resize', fixWidth);
    }

    return () => {
      window.removeEventListener('resize', fixWidth);
    };
  }, [isLoaded, fullscreen, parentRef, sliderRef, bgRef, hasMounted]);

  return (
    <Box
      sx={styles.carouselContainer}
      position={fullscreen ? 'static' : 'relative'}
      ref={containerRef}
    >
      {!isDisableFullscreen && Boolean(dataImg.length) ? (
        <Box sx={styles.iconButton}>
          <IconButton onClick={toggleFullScreen} aria-label="resize-image">
            {fullscreen ? <ArrowCollapse /> : <ArrowExpand />}
          </IconButton>
        </Box>
      ) : null}
      <Box
        ref={bgRef}
        sx={{
          borderRadius: isPropertyListCards ? '0.25rem' : null,
          height: '100%'
        }}
      >
        <Box
          id="alice-carousel-wrapper-box"
          sx={{
            ...styles.photoContainer,
            ...(isTransparentNavigation ? navigationOverlay : {}),
            ...(isPropertyDetail || isNewHomesDetails
              ? {
                  ...(!isNewHomesDetails ? aliceDotsStyles : {}),
                  ...(getImageStyles() === 'fullscreenPhoto'
                    ? {}
                    : propertyDetailCarouselWrapperStyles)
                }
              : {}),
            aspectRatio: photoAspectRatio,
            minHeight: isPropertyListCards
              ? '12rem'
              : photoAspectRatio
              ? 'auto'
              : { xs: '31.251rem', sm: '37.51rem' }
          }}
        >
          {!isVisible && !dataImg.length ? null : (
            <AliceCarousel
              ref={sliderRef}
              responsive={{ 0: { items: 1 } }}
              disableButtonsControls
              disableDotsControls={!hasMounted ? true : disableDotsControls}
              infinite
              mouseTracking
              keyboardNavigation={keyboardNavigation}
              swipeDelta={50}
              activeIndex={activeIndex}
              onSlideChanged={(e) => onSlideChanged(e.slide)}
              onInitialized={() =>
                !keepTrackOfActiveIndex
                  ? onSlideChanged(0)
                  : onSlideChanged(activeIndex)
              }
              innerWidth={getInnerWidth()}
              items={(!hasMounted ? [dataImg[0]] : dataImg).map(
                (imgItem, index) => {
                  const photo = imageLoaderWithRetina({
                    src: imgItem?.srcSet,
                    containerSize: containerRef?.current?.clientWidth
                  });

                  return (
                    <img
                      key={imgItem.id}
                      src={
                        !hasMounted
                          ? imgItem?.srcSet.replace('w/1600', `w/480`)
                          : photo.src
                      }
                      srcSet={
                        hasMounted
                          ? `${photo.src} 1x, ${photo.retinaSrc} 2x`
                          : undefined
                      }
                      alt={imgItem.title}
                      onDragStart={handleOnDragStart}
                      loading={index === 0 ? 'eager' : 'lazy'}
                      /* eslint-disable-next-line react/no-unknown-property */
                      fetchPriority={index === 0 ? 'high' : 'low'}
                      className={`${getImageStyles()} carousel-photo carousel-photo--loaded`}
                      onError={(img) => {
                        const imgElement = img.currentTarget;

                        // eslint-disable-next-line
                        imgElement.onerror = null;
                        // eslint-disable-next-line
                        imgElement.src = propertyFallbackImage;
                        // eslint-disable-next-line
                        imgElement.srcset = propertyFallbackImage;
                      }}
                    />
                  );
                }
              )}
            />
          )}
          {/* Arrows to change slide (next/back slide)   */}
          {dataImg.length === 1 ? null : (
            <Box
              sx={{
                padding: '0.25rem',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                top: '50%',
                width: '100%',
                transform: 'translateY(-50%)'
              }}
            >
              <Box
                onClick={handlePrevSlide}
                className={
                  isPropertyListCards
                    ? 'alice-carousel__prev-btn_property-cards'
                    : 'alice-carousel__prev-btn'
                }
              >
                <ArrowBackIosNewIcon />
              </Box>
              <Box
                onClick={handleNextSlide}
                className={
                  isPropertyListCards
                    ? 'alice-carousel__next-btn_property-cards'
                    : 'alice-carousel__next-btn'
                }
              >
                <ArrowForwardIosIcon />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {!isDisableSlideCount && Boolean(dataImg.length) ? (
        <Box
          sx={{
            ...styles.counterContainer,
            ...(isNewHomesDetails && styles.isNewHomesCounterContainer)
          }}
        >
          <Typography sx={styles.counter}>
            {activeIndex + 1} / {dataImg.length}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

function LibResponsiveCarousel({
  dataImg = [],
  imgWidth = '100%',
  imgHeight = '50%',
  isDisableFullscreen,
  isDisableSlideCount,
  isTransparentNavigation,
  isEagerImg = false,
  isNewHomesDetails = false,
  isPropertyDetail = false,
  isPropertyListCards = false,
  isAlreadyFullScreen = false,
  disableDotsControls = true,
  keepTrackOfActiveIndex = false,
  keyboardNavigation = false,
  handleFullScreen,
  photoAspectRatio,
  intersectionRootMargin = '50%'
}: LibResponsiveCarouselProps) {
  const sliderRef = useRef<AliceCarousel>(null);
  const bgRef = useRef<HTMLDivElement | null>(null);
  const carouselWrapper = useRef<HTMLDivElement | null>(null);
  const [fullscreen, setFullscreen] = useState(isAlreadyFullScreen);
  const [activeIndex, setActiveIndex] = useState(0);

  const intId = [];

  const slideBg = (next: boolean) => {
    if (intId[0]) {
      return;
    }

    const totalWidth = bgRef.current.offsetWidth;
    let pos = next ? totalWidth : 1;
    const interval = setInterval(frame, 1);

    intId.push(interval);

    function frame() {
      for (let i = 0; i < 10; i += 1) {
        if ((!next && pos >= totalWidth) || (next && pos <= 1)) {
          clearInterval(interval);
          intId[0] = null;
        } else {
          pos = next ? pos - 1 : pos + 1;
          bgRef.current.style.backgroundPositionX = `${pos}px`;
        }
      }
    }
  };

  const getImageStyles = () =>
    fullscreen
      ? 'fullscreenPhoto'
      : isPropertyDetail || isNewHomesDetails
      ? 'photoPropertyDetail'
      : 'photo';

  const handlePrevSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    sliderRef?.current?.slidePrev(e);
    slideBg(false);
  };

  const handleNextSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    sliderRef?.current?.slideNext(e);
    slideBg(true);
  };

  const onSlideChanged = (item: number) => {
    setActiveIndex(item);
  };

  const handleOnDragStart = (e: React.DragEvent<HTMLImageElement>) =>
    e.preventDefault();

  const toggleFullScreen = () => {
    setFullscreen((prev) => !prev);
    handleFullScreen?.(!fullscreen);
  };

  useEffect(() => {
    onSlideChanged(0);
  }, [dataImg]);

  if (!fullscreen) {
    return (
      <Carousel
        parentRef={carouselWrapper}
        activeIndex={activeIndex}
        bgRef={bgRef}
        fullscreen={fullscreen}
        getImageStyles={getImageStyles}
        handleNextSlide={handleNextSlide}
        handleOnDragStart={handleOnDragStart}
        handlePrevSlide={handlePrevSlide}
        isDisableFullscreen={isDisableFullscreen}
        isDisableSlideCount={isDisableSlideCount}
        isTransparentNavigation={isTransparentNavigation}
        onSlideChanged={onSlideChanged}
        toggleFullScreen={toggleFullScreen}
        sliderRef={sliderRef}
        dataImg={dataImg}
        disableDotsControls={disableDotsControls}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        isEagerImg={isEagerImg}
        isNewHomesDetails={isNewHomesDetails}
        isPropertyDetail={isPropertyDetail}
        isPropertyListCards={isPropertyListCards}
        keepTrackOfActiveIndex={keepTrackOfActiveIndex}
        keyboardNavigation={keyboardNavigation}
        photoAspectRatio={photoAspectRatio}
        intersectionRootMargin={intersectionRootMargin}
      />
    );
  }

  return (
    <Box ref={carouselWrapper}>
      <Dialog fullScreen open={fullscreen} onClose={toggleFullScreen}>
        <Box sx={styles.fullscreenWrapper}>
          <Carousel
            parentRef={carouselWrapper}
            activeIndex={activeIndex}
            bgRef={bgRef}
            fullscreen={fullscreen}
            getImageStyles={getImageStyles}
            handleNextSlide={handleNextSlide}
            handleOnDragStart={handleOnDragStart}
            handlePrevSlide={handlePrevSlide}
            isDisableFullscreen={isDisableFullscreen}
            isDisableSlideCount={isDisableSlideCount}
            isTransparentNavigation={isTransparentNavigation}
            onSlideChanged={onSlideChanged}
            toggleFullScreen={toggleFullScreen}
            sliderRef={sliderRef}
            dataImg={dataImg}
            disableDotsControls={disableDotsControls}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            isEagerImg={isEagerImg}
            isNewHomesDetails={isNewHomesDetails}
            isPropertyDetail={isPropertyDetail}
            isPropertyListCards={isPropertyListCards}
            keepTrackOfActiveIndex={keepTrackOfActiveIndex}
            keyboardNavigation={keyboardNavigation}
            photoAspectRatio={photoAspectRatio}
          />
        </Box>
      </Dialog>
    </Box>
  );
}

export const ResponsiveCarousel = React.memo(LibResponsiveCarousel);
